<template>
	<div>
		<h2>Informations</h2>
		<div class="box box-white">
			<div id="informations" v-if="horse">
				<div class="row">
					<div class="col-12">
						<div class="form-group mb-5 no_monceaux" v-if="editable">
							<label for="horse_nom">{{ $t('horse.form.nom') }}</label>
							<input type="text" class="form-control" v-model="horse.horse_nom">
						</div>
						<div class="mb-5 no_monceaux">
							<div class="row mb-2">
								<div class="col">
									<label>{{ $t('horse.identifiants') }}</label>
								</div>
								<div class="col-auto">

									<a
										v-e-online-only
										v-if="horse.horse_sire"
										class="btn btn-secondary rounded-pill"
										:href="`https://infochevaux.ifce.fr/externe/${horse.horse_sire}`"
										target="_blank"
									>
										{{ $t('horse.voir_fiche_ifce') }}
										<font-awesome-icon :icon="['fal', 'external-link-square-alt']" />
									</a>
									<button v-e-online-only v-if="has_sire" class="btn btn-secondary rounded-pill" @click="confirmSwitchSaveSireModal">
										{{ $t('horse.actualiser_donnees') }}
										<font-awesome-icon :icon="['fal', 'sync']" :spin="processing_sire" :disabled="processing_sire" />
									</button>
									<button v-e-online-only class="btn btn-secondary rounded-pill" @click="openModalDeces">
										{{ $t('horse.saisir_deces') }}
										<font-awesome-icon :icon="['fas', 'tombstone']"/>
									</button>
								</div>
							</div>
							<div class="row">
								<div class="col-12 col-md-6">
									<div class="form-group">
										<div class="input-group" >
											<div class="input-group-prepend">
												<span class="input-group-text">{{ $t("horse.numero_sire") }} : </span>
											</div>
											<input type="text" class="form-control" :readonly="readonly || has_sire" v-model="horse_sire_cle" v-mask="'00000000 U'">
											<div class="input-group-append" v-if="!readonly && isOkSire">
												<button class="btn btn-primary" type="button" @click.prevent="confirm_sire">{{ $t('global.valider') }}</button>
											</div>
										</div>
									</div>
								</div>
								<div class="col-12 col-md-6">
									<div class="form-group">
										<b-input-group :prepend="$t('horse.numero_ueln')">
											<template v-slot:append>
												<b-input-group-text>
													<span>
														<font-awesome-icon v-if="ueln_state === 'editing'" :icon="['fas', 'save']" v-on:click.prevent="saveHorseUeln" />
														<font-awesome-icon v-else-if="ueln_state === 'saving'" :icon="['fas', 'spinner']" pulse />
														<font-awesome-icon v-else-if="ueln_state === 'saved'" :icon="['fal', 'check']" />
													</span>
												</b-input-group-text>
											</template>

											<b-form-input
												placeholder="0000XX00000000X"
												v-model="horse_ueln"
												:readonly="readonly || !show_actions"
											/>
										</b-input-group>
									</div>
								</div>
							</div>
							<div class="form-group">
								<HorseTranspondeur v-if="horse.horse_id" 
									:horse_prop_transpondeur="horse.horse_transpondeur" 
									:horse_id="horse.horse_id" 
									:editable="show_actions"
									:readonly="readonly"
								/>
							</div>
						</div>

						<div id="infos_cheval" class="mb-5">
							<div class="form-group">
								<label class="no_monceaux">{{ $t("global.generales") }}</label>
								<div class="row">
									<div class="col-12 col-md-6">
										<div class="form-group">
											<div class="input-group">
												<b-input-group :append="getTrad('horse.form.datenaissance')">
													<input
														v-if="readonly || !show_actions"
														:readonly="readonly || !show_actions"
														:value="horse_datenaissance"
														class="form-control"
														type="text"
													/>
													<e-datepicker
														v-else
														v-model="horse.horse_datenaissance"
													/>
												</b-input-group>
											</div>
										</div>
									</div>
									<div class="col-12 col-md-6">
										<div class="form-group">
											<HorseSexe v-if="horse.horse_sexe" :editable="editable && show_actions" :horse_sexe_code="horse.horse_sexe" @change="changeSexe"/>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-12 col-md-6 no_monceaux">
										<div class="form-group">
											<HorseRace v-if="horse.horse_race" :editable="editable && show_actions" :horse_race_code="horse.horse_race" @change="changeRace"/>
										</div>
									</div>
									<div class="col-12 col-md-6">
										<div class="form-group">
											<HorseRobe v-if="horse.horse_robe" :editable="show_actions" :horse_robe_code="horse.horse_robe"  @change="changeRobe"/>
										</div>
									</div>
								</div>
								<div class="row no_monceaux">
									<div class="col-12 col-md-6">
										<div class="form-group">
											<HorseTaille v-if="horse.horse_id" :editable="show_actions" :horse_prop_taille.sync="horse.horse_taille" :horse_id="horse.horse_id" />
										</div>
									</div>
									
									<div class="col-12 col-md-6">
										<div class="form-group">
											<HorseInterne v-if="horse.horse_id" :editable="show_actions" :horse_prop_interne.sync="horse.horse_interne" :horse_id="horse.horse_id" />
										</div>
									</div>
								</div>
								<div class="row ">
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label for="horse_country" class="col-form-label">{{ $t("horse.country") }} *</label>
											<CountryInput v-model="horse.horse_country_code" :disabled="!show_actions" @change="changeCountry"></CountryInput>
										</div>
									</div>

									<div class="col-12 col-md-6 no_monceaux">
										<div class="form-group">
											<template v-if="horse.horse_id && (horse.horse_sexe == 'M' || horse.horse_sexe == 'H')">
												<b-form-checkbox id="horse_stallion" v-model="horse.horse_stallion" :disabled="!show_actions"><label for="horse_stallion">{{ $t("horse.male_reproducteur") }}</label></b-form-checkbox>
											</template>
										</div>
									</div>
								</div>
								<div v-if="horse.horse_mort" class="row">
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label>{{ $t('horse.date_deces') }}</label>
											<e-datepicker v-model="horse.horse_datemort" :disabled="true"/>
										</div>
									</div>

									<div class="col-12 col-md-6">
										<div class="form-group">
											<label>{{ $t('horse.cause_deces') }}</label>
											<input class="form-control" type="text" :disabled="true" v-model="causeDecesTrad"/>
										</div>
									</div>

									<div class="col-12 col-md-6">
										<div class="form-group">
											<label>{{ $t('horse.commentaire') }}</label>
											<textarea type="text" class="form-control" disabled="true" v-model="horse.horse_commentairemort"></textarea>
										</div>
									</div>
								</div>
                                <div v-if="hasFLCAccess" class="row">
									<div class="col-12 col-md-6">
										<div class="form-group">
											<label for="horse_country" class="col-form-label">{{ $t('horse.lien_connexe') }}</label>
											<!-- Lien France-Galop -->
											<div class="input-group" >
												<div class="input-group-prepend">
													<span class="input-group-text">France Galop : </span>
												</div>
												<input type="text" class="form-control" :readonly="!show_actions" v-model="horse.horse_francegalop">
                                                <div class="input-group-append" v-if="horse.horse_francegalop">
													<a :href="horse.horse_francegalop" class="input-group-text" target="_blank" rel="noopener noreferrer">
                                                        <font-awesome-icon :icon="['fas', 'external-link']" />
                                                    </a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="text-center" v-show="saveButton">
								<button class="btn btn-primary rounded-pill" @click='saveInfo'>Enregistrer <font-awesome-icon :icon="['fal', 'save']" class="ml-1" /></button>
								<button class="btn btn-secondary ml-4 rounded-pill" @click='cancelInfo'>Annuler <font-awesome-icon :icon="['fal', 'times']" class="ml-1"/></button>
							</div>
						</div>

						<HorseCategorie class="mb-5 no_monceaux" ref="horse_categorie" :selected_horse_categories="horse.categories" :horse_id="horse.horse_id" :readonly="!show_actions" />
						<div v-if="(horse_pedigree !== null && has_pedigree) || display_pedigree">
						<!-- <div> -->
							<label>{{ $t("horse.pedigree") }}</label>
							<table class="genealogie">
								<tbody>
									<tr>
										<td class="gen_principal" rowspan="4">
											<span v-if="!edit_pedigree_bool">{{ horse_pedigree.pedigree_nom_1 }}</span> <input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_1">
										</td>
										<td class="gen_secondaire" rowspan="2">
											<span v-if="!edit_pedigree_bool">{{ horse_pedigree.pedigree_nom_3 }}</span> <input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_3">
										</td>
										<td class="gen_secondaire" rowspan="1">
											<span v-if="!edit_pedigree_bool">{{ horse_pedigree.pedigree_nom_7 }}</span> <input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_7">
										</td>
									</tr>
									<tr>
										<td  rowspan="1">
											<span v-if="!edit_pedigree_bool">{{ horse_pedigree.pedigree_nom_8 }}</span> <input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_8">
										</td>
									</tr>
									<tr>
										<td  rowspan="2">
											<span v-if="!edit_pedigree_bool">{{ horse_pedigree.pedigree_nom_4 }}</span> <input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_4">
										</td>
										<td class="gen_secondaire" rowspan="1">
											<span v-if="!edit_pedigree_bool">{{ horse_pedigree.pedigree_nom_9 }}</span> <input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_9">
										</td>
									</tr>
									<tr>
										<td  rowspan="1">
											<span v-if="!edit_pedigree_bool">{{ horse_pedigree.pedigree_nom_10 }}</span> <input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_10">
										</td>
									</tr>
									<tr>
										<td class="nob" rowspan="4">
											<span v-if="!edit_pedigree_bool">{{ horse_pedigree.pedigree_nom_2 }}</span> <input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_2">
										</td>
										<td class="gen_principal" rowspan="2">
											<span v-if="!edit_pedigree_bool">{{ horse_pedigree.pedigree_nom_5 }}</span> <input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_5">
										</td>
										<td class="gen_secondaire" rowspan="1">
											<span v-if="!edit_pedigree_bool">{{ horse_pedigree.pedigree_nom_11 }}</span> <input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_11">
										</td>
									</tr>
									<tr>
										<td rowspan="1">
											<span v-if="!edit_pedigree_bool">{{ horse_pedigree.pedigree_nom_12 }}</span> <input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_12">
										</td>
									</tr>
									<tr>
										<td class="nob" rowspan="2">
											<span v-if="!edit_pedigree_bool">{{ horse_pedigree.pedigree_nom_6 }}</span> <input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_6">
										</td>
										<td class="gen_secondaire" rowspan="1">
											<span v-if="!edit_pedigree_bool">{{ horse_pedigree.pedigree_nom_13 }}</span> <input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_13">
										</td>
									</tr>
									<tr>
										<td class="nob" rowspan="1">
											<span v-if="!edit_pedigree_bool">{{ horse_pedigree.pedigree_nom_14 }}</span> <input type="text" class="form-control" v-if="edit_pedigree_bool" v-model="horse_pedigree.pedigree_nom_14">
										</td>
									</tr>
								</tbody>
							</table>
							<a href="#" @click.prevent="edit_pedigree" v-if="display_pedigree && !edit_pedigree_bool" class="float-right">{{ $t('horse.modifier_pedigree') }}</a>
							<a href="#" @click.prevent="valid_pedigree" v-if="display_pedigree && edit_pedigree_bool" class="float-right">{{ $t('horse.valider_pedigree') }}</a>

                            <iframe v-if="horse_pedigree.file" class="mt-4" :src="horse_pedigree.file.files_s3_url" width="800px" height="500px" />
						</div>
					</div>
				</div>

				<ModalConfirm
					ref="confirm_sire"
					id="confirm_sire_c"
					:icon="['far', 'save']"
					:icon_accept="['fal', 'level-down']"
					:text_title="$t('modal.horse.validation_sire')"
					:text_question="$t('modal.horse.text_validation_sire')"
					:text_button_ok="$t('modal.general.sauvegarder')"
					:text_button_accept="$t('modal.general.ok')"
					:text_button_cancel="$t('modal.general.annuler')"
					:callback_validation="confirmSwitchSaveSireModal"
					:callback_cancel="cancelSwitchSireModal"
					:processing="processing_sire"
				/>

				<b-modal ref="modal-deces" hide-footer>
					<template v-slot:modal-title>
						{{ $t('horse.saisir_deces') }}
					</template>

					<label>{{ $t('horse.date_deces') }}</label>
					<e-datepicker v-model="horse.horse_datemort" />

					<label>{{ $t('horse.cause_deces') }}</label>
					<e-select
						v-model="horse.horse_causemort"
						:options="causes_mort"
						:searchable="true"
					>
		                <template slot="option" slot-scope="{ option }">{{ $t('horse.cause_'+option) }}</template>
		                <template slot="singleLabel" slot-scope="{ option }">{{ $t('horse.cause_'+option) }}</template>
		                <template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>

					<label>{{ $t('horse.commentaire_deces') }}</label>
					<textarea type="text" class="form-control" v-model="horse.horse_commentairemort"></textarea>

					<div class="text-center">
						<a class="btn btn-primary mt-3" href="#" @click.prevent="saveDeces">{{ $t('global.enregistrer') }} <font-awesome-icon v-if="processing_deces" :icon="['fal', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" /></a>
					</div>
				</b-modal>
			</div>
		</div>
	</div>
</template>


<script type="text/javascript">
	import { EventBus } from 'EventBus'
	import Tools from "@/mixins/Tools.js"
	import HorseMixin from "@/mixins/Horse.js"

	export default {
		name: 'InformationsHorse',
		mixins: [HorseMixin, Tools],
		props:[
			'horse_id'
		],
		components: {
			HorseSexe: () => import('GroomyRoot/components/Horse/HorseSexe'),
			HorseRobe: () => import('GroomyRoot/components/Horse/HorseRobe'),
			HorseCategorie: () => import('GroomyRoot/components/Horse/HorseCategorie'),
			HorseTranspondeur: () => import('GroomyRoot/components/Horse/HorseTranspondeur'),
			HorseTaille: () => import('GroomyRoot/components/Horse/HorseTaille'),
			HorseInterne: () => import('GroomyRoot/components/Horse/HorseInterne'),
			HorseRace: () => import('GroomyRoot/components/Horse/HorseRace'),
			ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm'),
            CountryInput : () => import('GroomyRoot/components/Inputs/CountryInput')
		},
		data (){
			return {
				horse: null,
				horse_save: null,
				horse_pedigree: null,
				has_pedigree: false,
				saveButton: false,
				robe: null,
				race: null,
				horse_sire_cle: null,
				horse_ueln: null,
				sexe: null,
				update_button_type: '',
				editable: false,
				readonly: true, // si le cheval a un SIRE ou UELN
				display_pedigree: false,
				edit_pedigree_bool: false,
				processing_sire: false,
				ueln_state: 'initialized',
				tab_trad_manual_add: [
					'IK',
					'IF',
					'NOR',
				],
				horse_datenaissance: null,
				causes_mort: ['maladie_chien', 'pulmonaire', 'digestif', 'autre'],
				processing_deces: false
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.horse = await this.getHorseById(this.horse_id)
				this.horse.horse_stallion = (this.horse.horse_stallion === 1)
				this.horse_save = this.deppCloneObj(this.horse)
				this.horse_sire_cle = this.horse.horse_sire + ' ' + this.horse.horse_cle
				this.horse_ueln = this.horse.horse_ueln

				if(this.horse.horse_sire == null || this.horse.horse_sire == "") {
					this.horse.horse_sire = ''
					this.horse.horse_cle = ''
					this.horse_save.horse_sire = ''
					this.horse_save.horse_cle = ''
					this.horse_sire_cle = null
					this.editable = true
					this.readonly = false 
				}

				if(this.horse.horse_datenaissance) {
                    if(typeof(this.horse.horse_datenaissance) == 'object') {
    					this.horse_datenaissance = this.horse.horse_datenaissance.toLocaleDateString(this.$i18n.locale())
                    }
                    else {
                        const temp = new Date(this.horse.horse_datenaissance)
					    this.horse_datenaissance = temp.toLocaleDateString(this.$i18n.locale())
                    }
				}

				await this.loadPedigree()

				if(this.horse.horse_sire == null || this.horse.horse_sire == "") {
					if(this.show_actions) this.display_pedigree = true
				}
			},
			async loadPedigree() {
				this.horse_pedigree = await this.getHorsePedigree(this.horse_id)

				if (!this.horse_pedigree) {
					return
				}
				const pedigree_length = 14

				for(let i=1; i<pedigree_length+1; i++) {
					const pedigree_key = `pedigree_nom_${i}`

					if(!this.horse_pedigree[pedigree_key]) 
						this.horse_pedigree[pedigree_key] = 'NC'
					else 
						this.has_pedigree = true
				}
			},
			changeRobe(evt) {
				this.robe = evt
				this.horse.horse_robe = evt
				this.showSaveButton()
			},
			changeRace(evt) {
				this.race = evt
				this.horse.horse_race = evt
				this.showSaveButton()
			},
			changeSexe(evt) {
				this.sexe = evt
				this.horse.horse_sexe = evt
				this.showSaveButton()
			},
			changeTaille(evt) {
				this.taille = evt
				this.horse.horse_taille = evt
				this.showSaveButton()
			},
			changeCountry(evt) {
				this.horse.horse_country_code = evt
				this.showSaveButton()
			},
			showSaveButton() {
				this.saveButton = true
			},
			hideSaveButton() {
				this.saveButton = false
				this.update_button_type = ''
			},
			async saveInfo() {
				let status = false

				if(this.editable) {
					let variables = {}

					if(this.sexe != null){
						variables.horse_sexe = this.sexe
					}

					if(this.robe != null){
						variables.horse_robe = this.robe
					}

					if(this.race != null){
						variables.horse_race = this.race
					}

					if(this.horse.horse_nom != this.horse_save.horse_nom){
						variables.horse_nom = this.horse.horse_nom
					}

					if(this.horse.horse_datenaissance != this.horse_save.horse_datenaissance){
						variables.horse_datenaissance = this.horse.horse_datenaissance
					}

					if(this.horse.horse_taille != this.horse_save.horse_taille){
						variables.horse_taille = this.horse.horse_taille
					}

					if(this.horse.horse_interne != this.horse_save.horse_interne){
						variables.horse_interne = this.horse.horse_interne
					}

					if(this.horse.horse_stallion != this.horse_save.horse_stallion){
						variables.horse_stallion = this.horse.horse_stallion ? 1 : 0
					}

					if(this.horse.horse_country_code != this.horse_save.horse_country_code){
						variables.horse_country = this.horse.horse_country_code
					}

					status = true
					
					if(Object.keys(variables).length > 0) {
						status = await this.updateHorse(this.horse_id, variables)
					}

				}
				else if(this.update_button_type == 'horse_taille') {
					status = await this.updateHorseTaille(this.horse_id, this.horse.horse_taille)
				}
				else if(this.editable == false) {
					status = await this.updateHorseType(this.horse_id, this.sexe, this.robe, this.horse.horse_interne, (this.horse.horse_stallion == true ? 1 : 0), this.horse.horse_country_code)
				}

				let params = {
					title: this.getTrad('global.information'),
					body: this.getTrad('toast.info_modif_succes'),
					type: 'success'
				}

				// Si on a eu une erreur
				if(!status) {
					params.body = this.getTrad('toast.info_modif_failed')
					params.type = 'danger'
				}
				else {
					// Je veux mettre a jour le header
            		EventBus.$emit('HorseFiche::refreshHorse', {})
            		this.horse_save = this.deppCloneObj(this.horse)
				}

				EventBus.$emit('Header::displayToast', params)
				this.hideSaveButton()
			},
			cancelInfo() {
                const restored_horse = this.deppCloneObj(this.horse_save)
                restored_horse.horse_datenaissance = new Date(restored_horse.horse_datenaissance)
                
                this.horse = restored_horse
				this.hideSaveButton()
			},
			confirm_sire() {
				this.$refs.confirm_sire.openModal()
			},
			cancelSwitchSireModal() {
				this.$refs.confirm_sire.closeModal()
			},
			async confirmSwitchSaveSireModal() {
				if(this.processing_sire == false) {
					this.processing_sire = true

					let sire = this.horse_sire_cle.substr(0, 8)
					let cle = this.horse_sire_cle.substr(9, 1)

					let input = sire + cle

					let result = await this.search_sire_horse_manual(input, this.horse.horse_id)
					if(result.code_retour === 0) {
						EventBus.$emit('HorseFiche::refreshHorse', {})
						this.$refs.confirm_sire.closeModal()
						this.init_component()

						this.editable = false
						this.readonly = true
					}
					else {
						let error_message = ""

						if(this.tab_trad_manual_add.indexOf(result) > -1) {
							error_message = this.getTrad("horse.introuvable")
						}
						error_message = this.getTrad("error."+(result=="" ? "UKE" : result))
						this.failureToast(error_message, true)
					}
					this.processing_sire = false
				}
			},
			edit_pedigree() {
				this.edit_pedigree_bool = true
			},
			async valid_pedigree() {
				let response = await this.update_pedigree_horse_manual(this.horse.horse_id, this.horse_pedigree)

				if(response != null) {
					this.edit_pedigree_bool = false
					let params = {
						title: this.getTrad('global.information'),
						body: this.getTrad('toast.info_modif_succes'),
						type: 'success'
					}
					EventBus.$emit('Header::displayToast', params)

					this.loadPedigree()
				}
			},
			async saveHorseUeln() {
				this.ueln_state = 'saving'
				await this.updateHorse(this.horse.horse_id, {
					horse_ueln: this.horse_ueln
				})
				this.ueln_state = 'saved'
			},
			openModalDeces() {
				this.$refs['modal-deces'].show()
			},
			async saveDeces() {
				const params = {
					horse_datemort: this.horse.horse_datemort,
					horse_causemort: this.horse.horse_causemort,
					horse_commentairemort: this.horse.horse_commentairemort
				}
				this.processing_deces = true
				await this.updateHorseMort(this.horse_id, params)

            	EventBus.$emit('HorseFiche::refreshHorse', {})
            	this.init_component()
				this.$refs['modal-deces'].hide()
				this.processing_deces = false
			}
		},
		computed:{
			isOkSire(){
				if(this.horse_sire_cle && this.horse_sire_cle.length == 10) {
					return true
				}
				return false
			},
			show_actions() { // si la fiche cheval est en lecture seul
				return !this.$store.state.horseFicheReadonly
			},
			has_sire() {
				return this.horse.horse_sire !== null && this.horse.horse_sire !== ""
			},
            hasFLCAccess() {
                return this.$store.state.userAccess.hasFLCAccess
            },
            causeDecesTrad() {
            	return this.horse.horse_causemort ? this.getTrad('horse.cause_'+this.horse.horse_causemort) : ''
            }
		},
		watch: {
			'horse.horse_taille' (val, prev) {
				if(prev === undefined || prev === null) return

				this.update_button_type = 'horse_taille'
				this.showSaveButton()
			},
			horse: {
				handler(val){
					if(JSON.stringify(val) != JSON.stringify(this.horse_save)) {
						this.showSaveButton()
					}
					else {
						this.hideSaveButton()
					}
				},
				deep: true
			},
			'horse_id' (val) {
				this.init_component()	
			}
		}
	}
</script>